<template>
<section>
    <v-container>
        <v-row justify="start">
          <v-toolbar-title
            class="grey--text text--darken-4 font-weight-black my-5 ml-12"
            >
            Resident List
          </v-toolbar-title>
          <v-spacer />
          <v-text-field
              v-model="search"
              append-icon="search"
              label="Search resident"
              single-line
              hide-details
          >
          </v-text-field>
          <v-spacer />
          <v-btn
            class="grey--text text--darken-4 font-weight-black my-5 ml-12"
            v-on:click="refresh_resident_list"
            >
            Refresh
          </v-btn>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="residents"
          :options.sync="options"
          :server-items-length="totalResidents"
          :loading="loading"
          loading-text="Loading ..... Please wait"
          :footer-props="{
            itemsPerPageOptions: [100, 200, 300, 500],
            itemsPerPageText: 'Residents per page',
          }"
          update:options
          class="elevation-23"
        >
        <template v-slot:item.actions="{ item }">
                   <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    dark
                    v-on="on"
                  >
                    Actions
                  </v-btn>
                </template>
                <v-list v-if="$can('add')"
                  style="" class="overflow-y-auto">
                  <v-list-item
                    v-for="(list, index) in createList"
                    :key="index"
                    @click="controlDrugAdministration(item, list)"
                  >
                    <v-list-item-title>{{ list.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list v-if="$can('read')"
                  style="max-height: 100px" class="overflow-y-auto">
                  <v-list-item
                    v-for="(list, index) in viewList"
                    :key="index"
                    @click="controlDrugAdministration(item, list)"
                  >
                    <v-list-item-title>{{ list.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
          </template>
        </v-data-table>
    </v-container>
</section>
</template>
<script>
import residentApi from '@/services/api/ResidentApi'
export default {
    name: 'resident',
    components: {
    },
    data () {
        return {
            residents: [],
            totalResidents: 0,
            search: '',
            loading: true,
            options: {},
            routeCall: false,
            headers: [
              { text: 'Resident Name', value: 'fullName', class: 'success--text font-weight-bold' },
              { text: 'Medication', value: 'medication', class: 'success--text font-weight-bold' },
              { text: 'Start Date', value: 'startDate', class: 'success--text font-weight-bold' },
            { text: 'Actions', value: 'actions', sortable: false, class: 'success--text font-weight-bold'  },
          ],
          createList: [
              { title: 'Create New Medication' },
              { title: 'Create New Medication Administration' },
              { title: 'Create Control Drug' },
              { title: 'Create Control Drug Administration' },
              { title: 'Create Remedies' },
          ],
          viewList: [
              //   { title: 'View Medication' },
              //   { title: 'View Medication Administration' },
              // { title: 'View Control Drug' },
              // { title: 'View Control Drug Administration' },
              // { title: 'View Remedies' },
          ],
        }
    },
    methods: {
        get_resident_list () {
          this.loading = true
          return new Promise((resolve, reject) => {
              const { sortBy, sortDesc, page, itemsPerPage } = this.options
              if (sortDesc&& sortDesc.length && sortDesc[0] == false){
                var orde = 'asc'
              }
              else{
                 orde = 'desc'
              }
              if (['Admin', 'Administrator', 'Director', 'Supervisor', 'CCSO', 'CEO'].includes(localStorage.getItem('role'))){
                var homecode = ''
              }
              else{
                homecode = localStorage.getItem('homeCode')
              }
              residentApi.getResidents({page: page, count:itemsPerPage, home:homecode, q:'', orderBy: sortBy && sortBy[0], order: orde})
                .then(residents => {
                    this.residents = residents.result
                    this.totalResidents = residents.count
                }).catch(error => {
                    return reject(error)
                })
              setTimeout(() => {
                this.loading = false
                }, 1000)
          })
        },
        refresh_resident_list() {
          residentApi.refreshResident()
              .then(residents => {
                  this.residents = residents.result
                    this.totalResidents = residents.count
              })
              .catch(error => {
                    return Promise.reject(error)
                })
        },
        controlDrugAdministration (item, list) {
            switch (list.title){
              case 'Create New Medication':
                this.$router.push({ name: 'registerMedication', params: {residentId:item.id}});
                break;
              case 'Create New Medication Administration':
                this.$router.push({ name: 'registerMedicationAdministration', params: {residentId:item.id}});
                break;
              case 'Create Control Drug':
                this.$router.push({ name: 'createControlDrugRegister', params: {residentId:item.id}});
                break;
              case 'Create Control Drug Administration':
                this.$router.push({ name: 'createControlDrugAdmin', params: {residentId:item.id}});
                break;
              case 'Create Remedies':
                this.$router.push({ name: 'registerRemedy', params: {residentId:item.id}});
                break;
              // case 'View Medication':
              //   this.$router.push({ name: 'medications', params: {residentId:item.id}});
              //   break;
              // case 'View Medication Administration':
              //   this.$router.push({ name: 'medicationsAdministration', params: {residentId:item.id}});
                // break;
              // case 'View Control Drug':
              //   this.$router.push({ name: 'controlDrugList', params: {residentId:item.id}});
              //   break;
              // case 'View Control Drug Administration':
              //   this.$router.push({ name: 'controlDrugAdminList', params: {residentId:item.id}});
              //   break;
              // case 'View Remedies':
              //   this.$router.push({ name: 'remediesList', params: {residentId:item.id}});
              //   break;
            }
        },
    },
    watch: {
      options: {
          handler () {
            if(this.routeCall===false){
                this.get_resident_list()
                    .then(data => {
                        this.residents = data.result
                        this.totalMedications = data.count
                    })
            }
          },
          deep: true,
        },
      '$route.params.homeCode':  {
        handler () {
          this.routeCall = true;
          if (localStorage.getItem('role') == 'Admin' || localStorage.getItem('role')== 'Administrator'){
                var homecode = this.$route.params.homeCode
              }
              else{
                 homecode = localStorage.getItem('homeCode')
              }
          if (this.$route.params.homeCode){
            residentApi.getResidents({page:'', count:'', home:homecode})
                .then(resident => {
                    this.residents = resident.result
                    this.routeCall = true;this.routeCall = true;
                    this.totalResidents = resident.count
                })
                setTimeout(() => {
                this.loading = false
                this.routeCall = true
                }, 1000)
          }
          else{
            this.routeCall = false;
          }
      },
      deep: true,
      immediate: true
    },

    search: function() {
          if(this.search.length > 0){
              if (localStorage.getItem('role') == 'Admin' || localStorage.getItem('role')== 'Administrator'){
                var homecode = ''
              }
              else{
                 homecode = localStorage.getItem('homeCode')
              }
              return new Promise((resolve, reject) => {
                    residentApi.getResidents({page:'', count:'', home:homecode, q:this.search})
                    .then(resident => {
                        this.residents = resident.result
                        this.totalResidents = resident.count
                     })
                    .catch(error => {
                        return reject(error)
                    })
                })
          }
          if(this.search.length <= 0){
            this.get_resident_list()
                .then(data => {
                    this.residents = data.result
                    this.totalResidents = data.count
                })
          }
        },
    },
  }
</script>
<style lang="scss">
</style>
